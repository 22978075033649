import { DateTime, Interval } from 'luxon';

// Function to normalize strings
export function normalizeString(str) {
  return str
      .toUpperCase()
      .replace(/[‘’‛‹›]/g, "'")  // Replace curly apostrophes with straight ones
      .replace(/[“”«»]/g, '"')    // Replace curly quotes with straight ones
      .replace(/[–—]/g, '-')      // Replace en-dash and em-dash with hyphen
      .replace(/\s+/g, ' ')       // Replace multiple spaces with a single space
      .replace(/[^A-Z0-9\s\-'"]/g, '') // Remove any other special characters
      .trim();
}

export function extractNumberFromString(str) {
  const match = str.match(/^\d+/);
  return match ? parseInt(match[0], 10) : null;
}

export function calculateHours(timeIn, timeOut) {
  const timeInDateTime = DateTime.fromISO(timeIn);
  const timeOutDateTime = DateTime.fromISO(timeOut);
    console.log("Time in:", timeIn, "Time out:", timeOut);

  // Create intervals for the time in, time out, and regular hours
  const workInterval = Interval.fromDateTimes(timeInDateTime, timeOutDateTime);
  const regularInterval = Interval.fromDateTimes(
    timeInDateTime.set({ hour: 8, minute: 0 }),
    timeInDateTime.set({ hour: 17, minute: 0 })
  );

  // Calculate total working hours
  const totalWorkingHours = workInterval.toDuration('hours').hours;

  // Calculate overlap with regular hours
  const regularHoursInterval = workInterval.intersection(regularInterval);
  let regularHours = regularHoursInterval ? regularHoursInterval.toDuration('hours').hours : 0;

  // Calculate overtime hours
  let overtimeHours = totalWorkingHours - regularHours;
  overtimeHours = Math.max(overtimeHours, 0); // Ensure no negative overtime hours

  // Check for working more than 8 hours
  if (regularHours > 8) {
    overtimeHours += regularHours - 8;
    regularHours = 8;
  }

  // Round to the nearest tenth of an hour
  regularHours = Math.round(regularHours * 10) / 10;
  overtimeHours = Math.round(overtimeHours * 10) / 10;

  return { regularHours, overtimeHours };
}

export function formatTime(time) {
    const date = new Date(time);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
}

export const cost_code_map = 
{
  "GENERAL LABOR": 45.0,
  "SKILLED LABOR": 55.0,
  "PROJECT ADMINISTRATIVE ASSISTANT": 60.0,
  "SKILLED TECHNICIAN": 65.0,
  "HEALTH & SAFETY MANAGER": 79.0,
  "ENVIRONMENTAL SPECIALIST": 120.0,
  "REMEDIATION SUPERVISOR": 100.0,
  "PROJECT SUPERVISOR": 70.0,
  "PROJECT MANAGER": 105.0,
  "PROJECT ESTIMATOR": 130.0,
  "SENIOR PROJECT MANAGER": 170.0,
  "SENIOR PROJECT COORDINATOR": 200.0,
  "MOLD TECHNICIAN": 65.0,
"1-0-13 - SENIOR PROJECT MANAGER": 170,
"1-0-6 - SKILLED TECHNICIAN": 65,
"1-0-14 - SENIOR PROJECT COORDINATOR": 170,
"1-0-12 - PROJECT ESTIMATOR": 130,
"1-0-11 - ENVIRONMENTAL SPECIALIST": 120,
"1-0-10 - PROJECT MANAGER": 105,
"1-0-9 - REMEDIATION SUPERVISOR": 100,
"1-0-8 - HEALTH & SAFETY MANAGER": 79,
"1-0-7 - PROJECT SUPERVISOR": 70,
"1-0-5 - MOLD TECHNICIAN": 65,
"1-0-4 - INDUSTRIAL HYGIENIST": 100,
"1-0-3 - PROJECT ADMINISTRATIVE ASSISTANT": 60,
"1-0-2 - SKILLED LABOR": 55,
"1-0-1 - GENERAL LABOR": 45,
"1-2-85 - POLY - 6 MIL (2K SF)": 125,
"1-2-85 - Poly - 6 Mil Fire Retardant (2K SF)": 125,
"1-2-84 - POLY - 3 MIL COVER (2K SF)": 55,
"1-2-1 - WATER EXTRACTOR - OLYMPUS HYDRO FORCE - PORTABLE EXTRACTOR": 325,
"1-2-121 - WATER EXTRACTOR - PORTABLE EXTRACTOR": 325,
"1-2-121 - WATER EXTRACTOR - OLYMPUS HYDRO FORCE - PORTABLE EXTRACTOR": 325,
"1-2-112 - ANABEC ADVANCED CLEANING SOLUTION PLUS": 250,
"1-2-119 - OSCILLATING BLADES PACK": 50,
"1-2-120 - TOOL BATTERIES": 50,
"1-2-118 - GOGGLES": 20,
"1-2-117 - CHALK LINE": 20,
"1-2-116 - BOX TRUCK FULLY LOADED": 200,
"1-2-115 - EMERGENCY RESPONSE VEHICLE": 200,
"1-2-114 - BIOESQUE BOTANICAL DISINFECTANT SOLUTION": 140,
"1-2-113 - BIOESQUE MOLD RESISTANT COATING": 300,
"1-2-99 - TYVEK SUITS": 40,
"1-2-98 - TARP - 30' X 60'": 180,
"1-2-97 - TARP - 20' X 30'": 120,
"1-2-96 - TARP - 15' X 15'": 100,
"1-2-95 - TARP - 10' X 10'": 80,
"1-2-94 - TAPE - PAINTER'S TAPE": 9,
"1-2-93 - TAPE - PACKING (CLEAR)": 5,
"1-2-92 - TAPE - ENVIRONMENTAL BARRIER": 11,
"1-2-91 - TAPE - DUCT (STD. STRENGTH)": 9,
"1-2-90 - TAPE - CAUTION": 15,
"1-2-89 - SPRAY ADHESIVE": 12.5,
"1-2-88 - SHOE COVERS": 30,
"1-2-87 - SCRUBBING BUBBLES": 8,
"1-2-86 - ROOF CEMENT (ONE GALLON)": 50,
"1-2-83 - POLY - 10 MIL FIRE RETARDANT (2K SF)": 170,
"1-2-82 - PAPER TOWELS (WORK DUTY)": 4,
"1-2-81 - ODOR BLOCKS": 6,
"1-2-80 - MOP HEADS": 12,
"1-2-79 - MICRO BAN": 54,
"1-2-78 - METAL STUDS": 11,
"1-2-77 - LAY FLAT (24\" X 500 FT.) -- PRICING MAY VARY BY DIAMETER": 0.8,
"1-2-76 - GLOVES - MEDIUM DUTY": 9,
"1-2-75 - GLOVES - HEAVY DUTY DISPOSABLE (50 PR.)": 30,
"1-2-74 - GLOVES - BASIC WORK GLOVES": 6,
"1-2-73 - FURNITURE BLOCKS / EACH (1,008 PER BOX)": 0.1,
"1-2-71 - FILTER - SECONDARY PLEATED (A.S.)": 10,
"1-2-70 - FILTER - PRIMARY (A.S.)": 3,
"1-2-69 - FILTER - HEPA FOR VACUUM": 200,
"1-2-68 - FILTER - HEPA FOR SHOP VACUUM": 50,
"1-2-67 - FILTER - HEPA FOR AIR SCRUBBER": 250,
"1-2-66 - FILTER - DEHUMIDIFIER": 9.5,
"1-2-65 - FILTER - CHARCOAL": 150,
"1-2-64 - DUST MASKS N95 (20 PER BOX)": 30,
"1-2-63 - DISINFECT WIPES (35 PER PKG.)": 10,
"1-2-62 - DEGREASE-ALL": 35,
"1-2-61 - COTTON CLEANING RAG BOXES": 12,
"1-2-60 - CLEANER, GENERAL & ALL PURPOSE": 24,
"1-2-59 - CHERRY MATRIX": 35,
"1-2-58 - CHEMICAL SPONGES": 5,
"1-2-57 - BOX - MEDIUM (3.0 CUBIC FEET)": 7,
"1-2-56 - BOX - LARGE (4.0 CUBIC FEET)": 8,
"1-2-55 - BLEACH": 5,
"1-2-54 - BAGS, TRASH 3 MIL BLACK": 0.8,
"1-2-53 - BAGS, 6 MIL CLEAR": 2.75,
"1-2-52 - ADHESIVE REMOVER": 10,
"1-2-51 - VACUUM- UPRIGHT": 10,
"1-2-50 - VACUUM- SHOP VACUUM": 40,
"1-2-49 - VACUUM- HEPA": 75,
"1-2-46 - THERMAL IMAGING CAMERA": 150,
"1-2-45 - TELEPHONE- SATELLITE": 150,
"1-2-44 - TELEPHONE- MOBILE": 10,
"1-2-43 - SPRAY BOTTLE": 3.25,
"1-2-42 - SHOVEL/BROOM": 7,
"1-2-41 - SCAFFOLDING- BAKER": 25,
"1-2-40 - RESPIRATOR- HALF FACE W/ CARTRIDGE": 35,
"1-2-39 - RESPIRATOR- FULL FACE W/ CARTRIDGE": 45,
"1-2-38 - PUMP UP SPRAYER": 15,
"1-2-37 - PUMP- SUMP WITH HOSE (1/2 HP)": 75,
"1-2-36 - PRESSURE WASHER (UP TO 3500 PSI)": 75,
"1-2-35 - PERSONAL FALL PROTECTION": 65,
"1-2-34 - PAINT SPRAYER (AIRLESS)": 100,
"1-2-33 - HAND TOOLS- POWER (SAW, CIRCULAR)": 30,
"1-2-32 - HAND TOOLS- MICELLANEOUS": 20,
"1-2-30 - FOGGER (COMMERCIAL)": 100,
"1-2-29 - FLOOR SCRAPER (ELECTRICAL)": 8.25,
"1-2-28 - FLOOR KIT (BUCKET, BROOM, ETC NO INCLUDING MOP HEAD)": 20,
"1-2-26 - EXTENSION CORD- GENERAL USE": 10,
"1-2-25 - EXTENSION CORD- 10 GAUGE 50'": 20,
"1-2-24 - ELECTRICAL SPIDER BOX": 65,
"1-2-23 - ELECTRIC LOAD PANEL (200 AMP)": 65,
"1-2-22 - DOLLY- APPLIANCE": 13,
"1-2-21 - DOLLY- 4 WHEEL": 6.5,
"1-2-20 - CART/TILT/DEMO": 49,
"1-2-19 - CABLE RAMP": 20,
"1-2-18 - BUCKETS 5 GALLON": 20,
"1-2-17 - BOBCAT BUCKETS": 10,
"1-2-16 - BOBCAT (NO FUEL OR OPERATOR)": 125,
"1-2-15 - OZONE": 200,
"1-2-14 - MOISTURE READING EQUIPMENT": 20,
"1-2-13 - MICRO-MANOMETER- RECORDING": 100,
"1-2-12 - MICRO-MANOMETER": 25,
"1-2-11 - LOCK BOX": 45,
"1-2-9 - LIGHT, STRING": 30,
"1-2-8 - LIGHT STAND": 20,
"1-2-7 - LADDER- 40'": 35,
"1-2-6 - LADDER- 34'": 30,
"1-2-5 - LADDER- 24'": 24,
"1-2-4 - LADDER- 8 TO10'": 10,
"1-2-3 - INJECTDRY UNIT": 200,
"1-2-2 - HEATER- PROPANE/TORPEDO": 200,
"1-1-2 - AIR MOVER - 1000CFM": 40,
"1-1-2 - Air Mover- Carpet Fan": 40,
"1-1-12 - DEHUMIDIFIER - 25 GPD": 200,
"1-1-8 - Generator - <10 kW": 100,
"1-1-5 - AIR SCRUBBER": 125,
"1-1-3 - AIR MOVER- INDUSTRIAL FAN": 150,
"1-1-1 - AIR MOVER- AXIAL FAN": 75
}
