import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { OuterContainer } from "../style";
import { getAllProcoreProjectsApi } from "../../services/Api/collection";
import { errorMessage } from "../../utils/commonMethod";
import { DatePicker, Select, message } from "antd";
import { IconButton } from "rsuite";
import { Search } from "@rsuite/icons";
import { generateInvoice } from "../../utils/InvoiceGenerator";
import moment from 'moment';

const { Option } = Select;

const EquipmentWrapper = styled(OuterContainer)`
  .heading-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }

  .form-section {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .form-item {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .selectProject {
    width: 300px;
  }
  
  .ant-picker {
    width: 200px;
  }
`;

export const DailyLog = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [startDate, setStartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment());

  const getAllProcoreProjects = async () => {
    try {
      const res = await getAllProcoreProjectsApi();
      if (res?.status === 200) {
        setProjects(res?.data?.data);
      } else {
        errorMessage(res);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    getAllProcoreProjects();
  }, []);

  const handleProjectChange = (value) => {
    setSelectedProject(value);
  };

  const disabledStartDate = (current) => {
    return endDate && current && current.isAfter(endDate, 'day');
  };

  const disabledEndDate = (current) => {
    return (startDate && current && current.isBefore(startDate, 'day')) || 
           current.isAfter(moment(), 'day');
  };

  return (
    <EquipmentWrapper>
      <div className="heading-section">
        <p>Daily Log Generator</p>
      </div>
      <div className="form-section">
        <div className="form-item">
          <label>Select Project:</label>
          <Select
            className="selectProject"
            showSearch
            placeholder="Select a project"
            optionFilterProp="children"
            onChange={handleProjectChange}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {projects.map((project) => (
              <Option key={project.id} value={project.id}>
                {project.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="form-item">
          <label>Start Date:</label>
          <DatePicker
            onChange={(date) => setStartDate(date)}
            disabledDate={disabledStartDate}
            format="MM/DD/YYYY"
          />
        </div>
        <div className="form-item">
          <label>End Date:</label>
          <DatePicker
            onChange={(date) => setEndDate(date)}
            disabledDate={disabledEndDate}
            format="MM/DD/YYYY"
          />
        </div>
        <IconButton
          icon={<Search />}
          onClick={() => {
            if (startDate && endDate && startDate.isSameOrBefore(endDate, 'day') && 
                endDate.isSameOrBefore(moment(), 'day') && selectedProject) {
              generateInvoice(startDate.toDate(), endDate.toDate(), selectedProject);
            } else {
              message.error("Please select valid dates and a project");
            }
          }}
          color="orange"
          appearance="primary"
          className="refress-btn"
          style={{ marginLeft: "100px" }}
        />
      </div>
    </EquipmentWrapper>
  );
};